"use client";

import {
  IFixedDownloadButtonProps,
  IHeaderComponentProps,
} from "../../../lib/types/components";
import {
  publishWithUsType,
  sendPublishWithUsEvent,
} from "../../../lib/utils/constants/AnalyticsConstants";
import {
  GDC_HEADER_TYPE,
  GDC_WEB_URL,
  GET_APP_URL,
  HEADER_DOWNLOAD_BUTTON_TYPE,
  GET_APP_MANIA_PLAY_URL,
  THE_TECH_TRIUMPH_URL,
  GET_APP_REF_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import {
  getCountryFromLocale,
  showLanguagesToggle,
  showToggle,
} from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import GDCSubmitButton from "../FixedDownloadButton/GDCSubmitButton";
import HamburgerComponent from "../Hamburger/HamburgerComponent";
import LanguageToggleComponent from "../LanguageToggle/LanguageToggleComponent";
import React, { useEffect, useRef, useState } from "react";
import styles from "./HeaderComponentV2.module.css";
import useIsMobile from "../../../lib/customHooks/isMobile";
import {
  getLogoUrl,
  multipleClassName,
} from "../../../lib/utils/helpers/helper";
import {
  GAMESMANIA_DOT_IN,
  getWebsiteEnvironment,
  WINFANTASY_DOT_IN,
  WINZO_DOT_APP,
  WINZO_GLOBAL_URL,
  WINZO_JOGOS_DOT_COM_BR,
} from "../../../lib/utils/constants/EnvironmentConstants";
import { useDownloadContext } from "../../../lib/context/CommonContext";
import {
  COUNTRY,
  LANGUAGE_COUNTRY,
} from "../../../lib/utils/constants/LocaleAndCountryConstants";
import HeaderVersionToggle from "../../BTTC3/HeaderVersionToggle/HeaderVersionToggle";
import Analytics from "../../../lib/analytics/Analytics";
import { getGDCPageEvents } from "../../../lib/analytics/AnalyticsEvents";
import FixedDownloadButton from "../../GermanyDesign/FixedDownloadButton/FixedDownloadButton";

const sendAnalyticsGDCHedaer = () => {
  Analytics.getInstance().logEvent(getGDCPageEvents("Header_Submit_Clicked"));
};

const HeaderComponentV2 = (props: IHeaderComponentProps) => {
  const { isMobileDevice } = useIsMobile();
  const { menuOpen } = useDownloadContext();
  const [showCountryOptions, setShowCountryOptions] = useState(false);

  const {
    specialData,
    fixedDownloadButtonData,
    hideDownloadButton,
    isUtmHotstar,
    languageToggleData,
    locale,
    headerType,
    showSubmitButton,
    gdcFormUrl,
    gdcButtonText,
    selectLanguageText,
    pageName,
    storyFlow = false,
    disableSubmitButton = false,
    countryToggleData,
  } = props;

  let showLanguageToggle = showLanguagesToggle();
  if (
    locale === LANGUAGE_COUNTRY.PT_BR &&
    getWebsiteEnvironment().baseUrl === WINZO_GLOBAL_URL
  ) {
    showLanguageToggle = false;
  } else if (
    locale === LANGUAGE_COUNTRY.EN_IN &&
    getWebsiteEnvironment().baseUrl === WINZO_DOT_APP
  ) {
    showLanguageToggle = false;
  }

  const hideHamburger: boolean =
    props.hideHamburger ||
    (!(
      getCountryFromLocale(locale) === COUNTRY.GERMANY &&
      pageName === GET_APP_REF_URL
    ) &&
      pageName!.includes(GET_APP_URL));

  const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
    type: HEADER_DOWNLOAD_BUTTON_TYPE,
    fixedDownloadButtonData: fixedDownloadButtonData,
    pageName: pageName,
  };

  const toggleLanguageProps = {
    languageToggleData: languageToggleData,
    locale: locale,
    selectLanguageText,
  };

  const isGetApp = pageName === GET_APP_URL;
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      headerRef.current &&
      [WINFANTASY_DOT_IN, GAMESMANIA_DOT_IN].includes(
        getWebsiteEnvironment().baseUrl
      ) &&
      (pageName === GET_APP_URL || pageName === GET_APP_MANIA_PLAY_URL)
    ) {
      if (isMobileDevice) {
        headerRef.current.style.display = "flex";
      } else {
        headerRef.current.style.display = "none";
      }
    }
  }, [isMobileDevice, headerRef.current]);

  const handleCountryOptionToggle = () => {
    setShowCountryOptions( prev => !prev);
  }

  return fixedDownloadButtonData ? (
    <>
      <header
        ref={headerRef}
        className={styles.header}
        style={{
          display:
            [WINFANTASY_DOT_IN, GAMESMANIA_DOT_IN].includes(
              getWebsiteEnvironment().baseUrl
            ) &&
            (pageName === GET_APP_URL || pageName === GET_APP_MANIA_PLAY_URL)
              ? "none"
              : "flex",
        }}
      >
        <div className={"container"}>
          <nav className={multipleClassName("nav", styles.nav)}>
            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                {pageName && pageName.includes(GET_APP_URL) ? (
                  <CustomImageComponent
                    layout={"fill"}
                    objectFit={"contain"}
                    src={getLogoUrl(locale, pageName)}
                    alt="winzo games logo"
                    priority={true}
                  />
                ) : headerType === GDC_HEADER_TYPE ? (
                  <a
                    href={
                      pageName?.includes(THE_TECH_TRIUMPH_URL)
                        ? "/"
                        : "https://console.winzogames.com/"
                    }
                  >
                    <CustomImageComponent
                      layout={"fill"}
                      objectFit={"contain"}
                      src={getLogoUrl(locale, pageName)}
                      alt="winzo games logo"
                      priority={true}
                    />
                  </a>
                ) : (
                  <CustomLinkComponent href={"/"}>
                    <CustomImageComponent
                      layout={"fill"}
                      objectFit={"contain"}
                      src={getLogoUrl(locale, pageName)}
                      alt="winzo games logo"
                      priority={true}
                    />
                  </CustomLinkComponent>
                )}
              </div>
              <div
                className={multipleClassName(
                  "languageToggleGlobal",
                  styles.languageToggle
                )}
              >
                {showLanguageToggle && languageToggleData && (
                  <LanguageToggleComponent {...toggleLanguageProps} />
                )}
              </div>
              {countryToggleData && countryToggleData.showCountryToggle && countryToggleData.data
                .filter((item) => item.selected === true)
                .map((item) => {
                  return (
                    <>
                      <div className={styles.countryDropDownContainer} onClick={handleCountryOptionToggle}>
                        <div style={{ display: "flex", gap: 10 }}>
                          <div className={styles.flagImg}>
                            <CustomImageComponent
                              layout="fill"
                              objectFit="contain"
                              alt={""}
                              src={item.flagImg}
                            />
                          </div>
                          <div className={styles.countryName}>{item.value}</div>
                        </div>
                        <div className={styles.arrowImg}>
                          <CustomImageComponent
                            layout="fill"
                            objectFit="contain"
                            alt={""}
                            src={
                              "https://d3g4wmezrjkwkg.cloudfront.net/website/images/dropDownArrow.webp"
                            }
                            style={{transform: showCountryOptions ? "rotate(90deg)" : ""}}
                          />
                        </div>
                      </div>
                      {showCountryOptions && <div className={styles.countryOptionDropDownContainer}>
                        {countryToggleData.data.map((item) => {
                          return (
                            <CustomLinkComponent href={item.url}>
                              <div
                                className={styles.countryDropDownContainer2}
                                onClick={() => {
                                  setShowCountryOptions(false);
                                }}
                              >
                                <div className={styles.flagImg}>
                                  <CustomImageComponent
                                    layout="fill"
                                    objectFit="contain"
                                    alt={""}
                                    src={item.flagImg}
                                  />
                                </div>
                                <div className={styles.countryName1}>
                                  {item.value}
                                </div>
                              </div>
                            </CustomLinkComponent>
                          );
                        })}
                      </div>}
                    </>
                  );
                })}
            </div>

            <div className={styles.navItemsContainer}>
              <div
                className={styles.navItems}
                style={
                  typeof isMobileDevice === "undefined" || isMobileDevice
                    ? {
                        display: `${
                          headerType === GDC_HEADER_TYPE ? "block" : "none"
                        }`,
                      }
                    : {}
                }
              >
                <ul>
                  {!menuOpen &&
                    !isGetApp &&
                    specialData &&
                    specialData.map((item, index) => {
                      if (item.dropdown) {
                        return (
                          <li key={"specialData_" + index}>
                            <div className={styles.dropdown} key={index}>
                              <div className={styles.dropbtn}>{item.title}</div>
                              <div className={styles.dropdownContent}>
                                {item.dropdown.map((drop, i) => {
                                  if (
                                    getWebsiteEnvironment().baseUrl ===
                                      WINZO_JOGOS_DOT_COM_BR &&
                                    drop.link === "/pt-br/blog/"
                                  ) {
                                    drop.link = "/blog/";
                                  }
                                  return (
                                    <a
                                      href={drop.link}
                                      key={"item.dropdown_" + i}
                                    >
                                      {drop.title}
                                    </a>
                                  );
                                })}
                              </div>
                            </div>
                          </li>
                        );
                      }
                      if (item.special && !item.dropdown) {
                        if (item.isButtonType) {
                          return (
                            <a href={item.link}>
                              <button className={styles.season1}>
                                {item.title}
                              </button>
                            </a>
                          );
                        }
                        return (
                          <li key={index} className={styles.greenBackground}>
                            {item.outside ? (
                              <a
                                href={item.link}
                                target="_blank"
                                rel={"noreferrer"}
                                onClick={() => {
                                  item.link === GDC_WEB_URL &&
                                    sendPublishWithUsEvent(
                                      publishWithUsType.DESKTOP
                                    );
                                }}
                              >
                                <span>{item.title}</span>
                              </a>
                            ) : (
                              <CustomLinkComponent
                                href={item.link}
                                mobStyle={{
                                  textDecoration: "none",
                                }}
                                desktopStyle={{
                                  textDecoration: "none",
                                }}
                              >
                                <span>{item.title}</span>
                              </CustomLinkComponent>
                            )}
                          </li>
                        );
                      }
                      return <></>;
                    })}
                </ul>
              </div>
              {hideDownloadButton ? (
                <></>
              ) : getCountryFromLocale(locale) === COUNTRY.GERMANY ? (
                <FixedDownloadButton
                  {...fixedDownloadBtnProps}
                  storyFlow={storyFlow}
                />
              ) : (
                <FixedDownloadButtonV2
                  {...fixedDownloadBtnProps}
                  storyFlow={storyFlow}
                  showAnimation={false}
                />
              )}
              {headerType === GDC_HEADER_TYPE &&
                props.seasonToggleData &&
                !isMobileDevice && (
                  <HeaderVersionToggle {...props.seasonToggleData!} />
                )}
              {headerType === GDC_HEADER_TYPE && showSubmitButton ? (
                <GDCSubmitButton
                  text={gdcButtonText}
                  url={props.seasonStatus === -1 ? "" : gdcFormUrl}
                  type={HEADER_DOWNLOAD_BUTTON_TYPE}
                  sendAnalytics={sendAnalyticsGDCHedaer}
                  disabled={disableSubmitButton}
                />
              ) : (
                <></>
              )}
              {!hideHamburger && <HamburgerComponent />}
            </div>
          </nav>
        </div>
      </header>
    </>
  ) : (
    <></>
  );
};

export default HeaderComponentV2;
